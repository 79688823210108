import React from 'react';
import SEO from "../components/seo";
  import Layout from "../components/layout";



const Legal = ({data}) => (
  <Layout>
    <SEO title="Legal" />
    <div className='contentBox'>
      <h2>Terms of Service</h2>
      This site is protected by reCAPTCHA.  Google&nbsp;<a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
    
    <div className='contentBox'>
      <h2>Privacy Policy</h2>
      This site is protected by reCAPTCHA.  Google&nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a> applies.
    </div>
  </Layout>
);

export default Legal;